.silo-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: var(--unsupported-blurry);
    border-radius: var(--containers-radius);
    padding: 1rem;
    min-width: 20rem;
    transition: transform 0.2s;
    gap: 1rem;
    animation: fadeIn 0.2s ease-in
}

.expanded {
    animation: expandAnimationHorizontal 0.5s forwards;
}

.silo-container:hover:not(.expanded) {
    transform: scale(0.99);
}

.silo-navbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.silo-navbar h2 {
    color: var(--cat-yellow);
    margin-top: var(--paragraph-margin);
    margin: 0;
}

.silo-navbar h4 {
    color: var(--inactive);
    margin-top: var(--paragraph-margin);
    font-size: small;
    margin: 0;
}

.silos-buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.expand-icon {
    color: white;
    border-radius: var(--round);
    padding: var(--round-icon-padding);
    visibility: hidden;
    transition: all 0.2s ease-in-out;
}

.expand-icon:hover {
    background-color: var(--blurry);
}

.rotated {
    transform: rotate(-180deg);
    transition: transform 0.4s ease-in-out;
}

.rotated:hover {
    transition: background-color 0.2s ease-in-out;
}

.silo-container:hover .expand-icon {
    visibility: visible;
    opacity: 1;
}

.section-splitter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 100%;
    width: 100%;
    gap: 2rem;
    transition: gap 0.3s ease, padding-right 0.3s ease;
}

.section-preview-loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.section-preview {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
}

.preview-data-splitter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
}

.animated-icons-container {
    display: flex;
    flex-direction: row;
}

.bypass {
    opacity: 0.2;
}

.loading-update-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
}

.update-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: var(--cat-yellow);
    margin: var(--paragraph-margin);
}

.update-message-container p {
    color: white;
    font-size: small;
}

.update-icon {
    color: white;
    width: 60px;
    height: 60px;
}

.section-full-data {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.section-full-data-info-container {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0;
}

.section-full-data-info-container h3 {
    color: white;
    overflow: hidden;
    margin: var(--paragraph-margin);
}

.section-full-data-info-container p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    overflow: hidden;
    font-size: small;
    margin: var(--paragraph-margin);
    margin-top: 0;
}

.section-full-data-info-container span {
    color: var(--cat-yellow)
}

.section-full-data-graph-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.chart-loading {
    margin-top: 2rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--cat-yellow);
    padding: 1rem;
}

.chart-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 12rem;
}

.chart-container h3 {
    color: white;
    margin: var(--paragraph-margin)
}

.chart-empty {
    margin-top: 0.5rem;
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--cat-yellow);
    padding: 1rem;
}

.chart-empty-icon {
    width: 40px;
    height: 40px;
    color: white;
}

.data-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.data-icon {
    color: white;
}

.animated-icon {
    height: 40px;
    width: 40px;
    color: white;
    border-radius: var(--round);
    padding: var(--round-icon-padding);

}

.vent-running {
    animation: ventAnimation 0.5s linear infinite;
}

.vibration-running {
    animation: vibrationAnimation 0.5s linear infinite;
}

.data-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
}

.data-element .reliability-level {
    color: var(--cat-yellow);
}

.tridimensional-toggle-container {

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.2rem;
    color: white;
}

.tridimensional-icon {
    width: 20px;
    height: 20px;
    color: white;
}

.tridimensional-switch {
    margin-left: 10px;
}

.data-container h3 {
    color: white;
    margin-top: var(--paragraph-margin)
}

.data-container p {
    color: white;
    font-size: small;
    margin: 0;
}

.tridimensional-container {
    height: 28rem;
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.svg-container {
    position: relative;
    margin-top: 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.sleep-container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: aqua;
}

.zzz {
    animation-name: zzz;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-direction: forwards;
    color: #ffc40000;
    font-weight: bold;
    position: absolute;
    z-index: 100;
    transform: translateY(100%);
}

.zzz-z {
    animation-delay: 0s;
    right: 10px;
}

.zzz-zz {
    animation-delay: 0.5s;
    right: -20px;
}

.zzz-zzz {
    animation-delay: 1s;
    right: 0;
}

/**************************************************************/
/********************* Global and support *********************/
/**************************************************************/

/* In case some browser support transparent blur*/
@supports (backdrop-filter: blur(1rem)) {
    .silo-container {
        background: var(--blurry);
        backdrop-filter: blur(0.75rem);
    }
}

/**************************************************************/
/************************* Responsive *************************/
/**************************************************************/

/* Small desktop */
@media (max-height: 750px) {
    .svg-container {
        margin: 0;
        transform: scale(0.85);
    }

    .silo-container:hover:not(.expanded) {
        transform: scale(1.00);
    }

    .section-preview {
        justify-content: flex-start;
    }
}

/* Small phones */
@media (max-height: 720px) {
    .section-full-data {
        margin-top: 1.5rem;
    }

    .chart-container {
        visibility: collapse;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 90%;
        height: 12rem;
    }

    .silo-container:hover:not(.expanded) {
        transform: scale(1.00);
    }

    .chart-empty {
        margin-top: 0.2rem;
        display: flex;
        width: 90%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--cat-yellow);
        padding: 1rem;
    }

    .chart-empty-icon {
        width: 20px;
        height: 20px;
        color: white;
    }
}

/* Mobile phones */
@media (max-width: 768px) {
    .section-full-data {
        margin-top: 1.5rem;
    }

    .silo-container {
        min-width: 22rem;
        min-height: 100%;
    }

    .expanded {
        animation: expandAnimationVertical 0.5s forwards;
    }

    .expanded .section-preview {
        height: 3rem;
    }

    .expanded .svg-container {
        visibility: collapse;
    }

    .expanded .tridimensional-toggle-container {
        visibility: collapse;
    }

    .expand-icon {
        color: white;
        border-radius: var(--round);
        padding: var(--round-icon-padding);
        visibility: visible;
        transform: rotate(90deg);
        transition: transform 0.2s ease-in-out;
    }

    .expand-icon.rotated {
        transform: rotate(270deg);
        transition: transform 0.2s ease-in-out;
    }

    .section-splitter {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 1rem;
        transition: gap 0.3s ease, padding-right 0.3s ease;
    }

    .chart-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 90%;
        height: 14rem;
    }
}

/* X-small mobile phones */
@media (max-width: 380px) {
    .section-full-data {
        margin-top: 1.5rem;
    }

    .silo-container {
        min-width: 20rem;
        min-height: 100%;
    }

    .expanded {
        animation: expandAnimationVertical 0.5s forwards;
    }

    .expanded .section-preview {
        height: 3rem;
    }

    .expanded .svg-container {
        visibility: collapse;
    }

    .expanded .tridimensional-toggle-container {
        visibility: collapse;
    }

    .expand-icon {
        color: white;
        border-radius: var(--round);
        padding: var(--round-icon-padding);
        visibility: visible;
        transform: rotate(90deg);
        transition: transform 0.2s ease-in-out;
    }

    .expand-icon.rotated {
        transform: rotate(270deg);
        transition: transform 0.2s ease-in-out;
    }

    .section-splitter {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 2rem;
        transition: gap 0.3s ease, padding-right 0.3s ease;
    }

    .chart-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 90%;
        height: 14rem;
    }

}

/**************************************************************/
/************************* Animations *************************/
/**************************************************************/

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes expandAnimationHorizontal {
    0% {
        width: 20rem;
    }

    100% {
        width: 45rem;
    }
}

@keyframes expandAnimationVertical {
    0% {
        height: auto;
    }

    100% {
        height: 100%;
    }
}

@keyframes ventAnimation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes vibrationAnimation {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(10deg);
    }

    50% {
        transform: rotate(-10deg);
    }

    75% {
        transform: rotate(5deg);
    }

    100% {
        transform: rotate(-5deg);
    }
}

@-webkit-keyframes zzz {
    0% {
        color: rgba(255, 255, 255, 0);
        font-size: 8px;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }

    100% {
        color: white;
        font-size: 22px;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

@keyframes zzz {
    0% {
        color: rgba(255, 255, 255, 0);
        font-size: 8px;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }

    100% {
        color: white;
        font-size: 22px;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}